@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
    background: linear-gradient(rgba(0, 0, 0, .53), rgba(0, 0, 0, .53)), url("../public/images/hero_image.jpg");
}

.bathroom {
    background: linear-gradient(rgba(0, 0, 0, .53), rgba(0, 0, 0, .53)), url("../public/images/bathroom.jpg");
    background-position: 50% 60%;
}

.flooring {
    background: linear-gradient(rgba(0, 0, 0, .53), rgba(0, 0, 0, .53)), url("../public/images/flooring.jpg");
    background-size: cover;
    background-position: center;
}

.kitchen {
    background: linear-gradient(rgba(0, 0, 0, .53), rgba(0, 0, 0, .53)), url("../public/images/kitchen.jpg");
    background-position: 50% 45%;
}

.tile {
    background: linear-gradient(rgba(0, 0, 0, .53), rgba(0, 0, 0, .53)), url("../public/images/tile.jpg");
    background-size: cover;
    background-position: 70% 40%;
}